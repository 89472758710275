<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>

    <v-row class="align-center d-flex h-0 pb-0">
        <v-col xl="3" lg="4" sm="8" class="mx-auto">
            <checkmong-home-bar :title="menu_title"></checkmong-home-bar>
        </v-col>
    </v-row>
    <br />
    <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
        <v-col xl="3" lg="4" sm="8" style="text-align: center;">

            <v-avatar color="" style="box-shadow: 0px 0px 7px rgba(31, 152, 189, 0.15);background: rgba(251, 251, 251, 0.64); height: 77px; width: 77px;">
                <v-icon x-large class="text-purple" style="font-size:68px">
                    person
                </v-icon>
            </v-avatar>

        </v-col>
    </v-row>

    <div v-if="user_type == 'user_provider'">

        <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8" class="row-no-padding" style="text-align: end;">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon  v-bind="attrs" v-on="on">
                            <v-icon dense class="text-blue">
                                edit_note
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>ต้องการแก้ไขข้อมูล โปรดติดต่อแอดมินผ่านช่องทางแชท</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-card style="border-radius: 15px" elevation="1">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>account_box</v-icon>
                            </v-col>
                            <v-col cols="11">
                                ชื่อ-นามสกุล
                                <v-chip label style="padding-left: 22px;  width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.fullname}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>featured_play_list</v-icon>
                            </v-col>
                            <v-col cols="11">
                                เลขที่ใบประกอบวิชาชีพเวชกรรม
                                <v-chip label style="padding-left: 22px; width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.mdnumber}}
                                </v-chip>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>home</v-icon>
                            </v-col>
                            <v-col cols="11">
                                ชื่อคลินิก / สถานพยาบาล
                                <v-textarea name="input-7-1" hide-details filled readonly auto-grow rows="3" rounded row-height="25" :value="res_info.provider_name" background-color="#F9FBFC" style="
                                border-radius: 10px;    
                                margin-top: 3px;
                                padding-left: 0px;
                                ">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <div v-if="user_type == 'user_osm'">

        <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8" class="row-no-padding" style="text-align: end;">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon  v-bind="attrs" v-on="on">
                            <v-icon dense class="text-blue">
                                edit_note
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>ต้องการแก้ไขข้อมูลโปรดติดต่อแอดมินผ่านช่องทางแชท</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-card style="border-radius: 15px" elevation="1">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>account_box</v-icon>
                            </v-col>
                            <v-col cols="11">
                                ชื่อ-นามสกุล
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.fullname}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>featured_play_list</v-icon>
                            </v-col>
                            <v-col cols="11">
                                เลขบัตรประชาชน
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.idcard}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>phone</v-icon>
                            </v-col>
                            <v-col cols="11">
                                เบอร์โทรศัพท์
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.phone_number}}
                                </v-chip>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <br>
    <br>
    <div v-if="user_type == 'user_patient'">
        <v-row class="align-center d-flex h-0 pb-0 pt-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8" style="text-align: center; padding-bottom: 0px;padding-top: 0px;">
                <v-btn @click="$router.push('/qr_gen')" depressed small rounded color="bg-white" style="height: 39px;">
                    <v-icon left>
                        qr_code_2
                    </v-icon>
                    <span class="text-purple">คิวอาร์โค้ดของฉัน</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8" class="row-no-padding" style="text-align: end;">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="dialog=true" v-bind="attrs" v-on="on">
                            <v-icon dense class="text-blue">
                                edit_note
                            </v-icon>
                        </v-btn>
                    </template>
                    <!-- <span>ฟังก์ชั่นแก้ไขข้อมูลจะสามารถใช้งานได้เร็วๆนี้</span> -->
                </v-tooltip>
            </v-col>
        </v-row>

        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col lxl="3" lg="4" sm="8">
                <v-card style="border-radius: 15px" elevation="1">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon color="">account_box</v-icon>
                            </v-col>
                            <v-col cols="11">
                                ชื่อ-นามสกุล
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.fullname}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>featured_play_list</v-icon>
                            </v-col>
                            <v-col cols="11">
                                เลขบัตรประชาชน
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.idcard}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>phone</v-icon>
                            </v-col>
                            <v-col cols="11">
                                เบอร์โทรศัพท์
                                <v-chip label style="width: 100%;background: rgba(134, 134, 134, 0.04);border-radius: 10px;">{{res_info.phone_number}}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1" style="padding-top:28px">
                                <v-icon>home</v-icon>
                            </v-col>
                            <v-col cols="11">
                                ที่อยู่
                                <v-textarea name="input-7-1" hide-details filled readonly auto-grow rows="3" rounded row-height="25" :value="res_info.full_address" background-color="rgba(134, 134, 134, 0.04)" style="
                                    border-radius: 10px;    
                                    margin-top: 3px;
                                    padding-left: 0px;"></v-textarea>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </div>
    <v-row>
        <v-col cols="12" style="text-align:center;">
            <v-chip v-if="$store.state.browser === 'browser'" @click="logOut" style="background-color:#f2ecd1 !important;">
                <span style="color:purple;">ออกจากระบบ</span>
            </v-chip>
        </v-col>
    </v-row>
    <v-col xl="3" lg="4" sm="8">

        <!-- ////////////////////////////////////////ปิดใช้งานการแก้ไขชั่วคราว -->
        <v-dialog fullscreen width="100%" v-model="dialog" persistent style="">
            <!-- <v-dialog width="500" v-model="s" persistent style=""> -->
            <v-card>
                <v-card-title class="text-purple">
                    แก้ไขข้อมูล
                </v-card-title>
                <v-card-text>
                    <v-card class="" style="">
                        <v-card-text style="border-radius: 7px; padding-top: 25px">
                            <v-row v-if="user_type == 'user_patient'" dense align="center">
                                <v-form ref="form_2" v-model="valid" lazy-validation style="width: 100%">
                                    <v-select class="custom-field" v-model="prefix" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                                    <v-text-field v-model="first_name" :rules="nameRules" label="ชื่อ" dense outlined required></v-text-field>
                                    <v-text-field v-model="last_name" :rules="nameRules" label="นามสกุล" dense outlined required></v-text-field>

                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="birthdate" :rules="dateRules" label="วัน/เดือน/ปีเกิด" append-icon="calendar_month" dense outlined required readonly v-bind="attrs" v-on:click:append="menu=true" v-on="on"></v-text-field>

                                        </template>
                                        <v-date-picker v-model="birthdate" locale="th-th" :active-picker.sync="activePicker" no-title :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1950-01-01" @change="save"></v-date-picker>
                                    </v-menu>

                                    <v-row class="margin-bottom: -21px;">
                                        <v-col>
                                            <v-text-field v-model="weight" type="tel" :rules="wRules" label="น้ำหนัก (kg)" disabled dense outlined></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="height" type="tel" :rules="wRules" label="ส่วนสูง (cm)" dense outlined></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <span>โรคประจำตัว</span>
                                    <v-row>
                                        <v-col>
                                            <v-checkbox v-model="highpressure" label="ความดันสูง" color="primary" dense hide-details></v-checkbox>
                                            <v-checkbox v-model="hyperlipidemia" label="ไขมันสูง" color="primary" dense hide-details></v-checkbox>
                                            <v-checkbox v-model="alzheimer" label="อัลไซเมอร์" color="primary" dense hide-details></v-checkbox>

                                        </v-col>
                                        <v-col>
                                            <v-checkbox v-model="diabetes" label="เบาหวาน" color="primary" dense hide-details></v-checkbox>
                                            <v-checkbox v-model="arrhythmai" label="หัวใจเต้นผิดจังหวะ" color="primary" dense hide-details></v-checkbox>
                                            <v-checkbox v-model="depress" label="ซึมเศร้า" color="primary" dense hide-details></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-checkbox v-model="heart" label="โรคหัวใจหรือหลอดเลือดสมอง" color="primary" dense hide-details style="padding-bottom: 21px;"></v-checkbox>

                                    <v-text-field counter="5" v-model="postcode" type="tel" :rules="nameRules" label="รหัสไปรษณีย์" dense outlined required></v-text-field>

                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="province" label="จังหวัด" dense outlined disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select v-model="selected_district" :item-value="district_list" :items="district_list" :rules="[(v) => !!v || 'โปรดเลือก']" label="อำเภอ/เขต" dense outlined required></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select v-model="location_id" :item-value="'location_id'" :item-text="'subdistrict'" :items="filterSubdistrict" :rules="[(v) => !!v || 'โปรดเลือก']" label="ตำบล/แขวง" dense outlined required></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-text-field v-model="address" label="เลขที่ อาคาร/หมู่บ้าน ถนน" dense outlined></v-text-field>
                                    <v-text-field type="tel" v-model="phone_number" :rules="phoneRules" label="เบอร์โทรศัพท์" dense outlined required></v-text-field>
                                    <!-- {{selected_district}} -->
                                    <!-- {{selected_district_list}} -->
                                    <!-- {{filterSubdistrict}} -->
                                    <!-- {{location_id}} -->
                                    <br>

                                </v-form>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="purple" text @click="dialog = false">
                        ยกเลิก
                    </v-btn>
                    <v-btn color="#A84192 " class="text-white" @click="validate">
                        ยืนยันการแก้ไข
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
        <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
        </v-col>

    </v-row>

    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-left">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                <v-icon small>west</v-icon>
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import {
    LIFFID
} from '@/constants'
// import {
//     APIURL
// } from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        location_id: '',
        selected_district: '',
        district_list: [],
        province: '',
        postcode: '',
        highpressure: 0,
        hyperlipidemia: 0,
        alzheimer: 0,
        heart: 0,
        diabetes: 0,
        arrhythmai: 0,
        depress: 0,
        weight: '',
        height: '',
        prefix: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        phone_number: '',
        idcard: '',
        valid: true,
        menu_title: '',
        res_info: {},
        update_status_wait: '',
        fullname: '',
        mdnumber: '',
        active_waiting: false,
        status: '',
        user_type: '',
        user_id: '',
        title: '',
        address: '',
        provider_name: '',
        activePicker: null,
        date: null,
        menu: false,
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        nameRules: [
            v => !!v || "โปรดกรอกข้อมูล",

        ],
        phoneRules: [
            v => (v && v.length > 8 && v.length < 11) || "โปรดกรอกเลขหมายที่ถูกต้อง"
        ],
        wRules: [
            v => (v && v > 30 && v < 220) || "โปรดกรอกจำนวนที่ถูกต้อง"
        ],
        dateRules: [v => !!v || "โปรดเลือกวันที่", ],
    }),

    mounted() {
        //console.log('home---')
        //console.log(this.$store.state)
        this.user_type = this.$store.state.login_detail.user_type
        this.user_id = this.$store.state.login_detail.user_id
        this.getHomeTitle()
        this.getUserInfo()
        this.setUpEditValue()
        console.log('this.user_id:',this.user_id)
    },
    computed: {
        filterSubdistrict: function () {
            // //console.log(this.postcode)
            if (!this.postcode || this.postcode.length < 5 || !this.filterSubdistrictByDistrict(this.selected_district)) {
                return []
            }
            return this.filterSubdistrictByDistrict(this.selected_district)
        },

    },

    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    watch: {
        async postcode(val) {
            if (val && val.length == 5) {
                this.active_waiting = true
                this.title = '',
                    this.status = 'loading'
                await Vue.axios.post(APIURL + '/get_location', {
                    "postcode": val
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data) {
                        this.active_waiting = false
                        //console.log(response.data)
                        this.province = response.data.province
                        this.district = response.data.district
                        this.subdistrict_list = response.data.subdistrict_list
                        this.location_id = response.data.location_id
                        this.location_list = response.data.location_list
                        //console.log('*******************')
                        //console.log(this.location_id)
                        this.getDistrictKeys()
                    } else {
                        this.active_waiting = false
                        this.province = 'ไม่พบข้อมูล'
                        this.district = 'ไม่พบข้อมูล'
                    }

                })
            }

        }
    },
    methods: {
        
        setUpEditValue() {
            if(this.res_info.postcode){
                this.postcode = this.res_info.postcode,
                this.highpressure = parseInt(this.res_info.highpressure),
                this.hyperlipidemia = parseInt(this.res_info.hyperlipidemia),
                this.alzheimer = parseInt(this.res_info.alzheimer),
                this.heart = parseInt(this.res_info.heart),
                this.diabetes = parseInt(this.res_info.diabetes),
                this.arrhythmai = parseInt(this.res_info.arrhythmai),
                this.depress = parseInt(this.res_info.depress),
                this.weight = this.res_info.weight,
                this.height = this.res_info.height,
                this.prefix = this.res_info.prefix,
                this.first_name = this.res_info.first_name,
                this.last_name = this.res_info.last_name,
                this.birthdate = this.res_info.birthdate,
                this.phone_number = this.res_info.phone_number,
                this.address = this.res_info.address
            }

        },
        filterSubdistrictByDistrict: function (subdistrict_list) {
            //console.log(subdistrict_list)
            return this.selected_district ?
                this.location_list.filter((location_list) => !location_list.district.indexOf(this.selected_district)) :
                this.location_list;

        },
        getDistrictKeys() {
            const temp_list = this.location_list.reduce((prev, cur) => ({
                ...prev,
                [cur.district]: (prev[cur.district] || []).concat(cur)
            }), {})
            const keys = Object.keys(temp_list);
            // print all keys
            //console.log(keys);
            this.district_list = keys
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        validate() {
            const res = this.$refs.form_2.validate();
            //console.log(res)
            if (res) {
                this.submitRegister()
            }
            // 
        },

        async submitRegister() {
            // send submit_form to the server
            // await        
            //console.log('------------------->', this.provider_list_id)
            if (this.provider_list_id == 0) {
                this.provider_list_id = 1
            }

            this.register_form = {
                "user_type": this.user_type,
                "user_id": this.user_id,
                "location_id": this.location_id,
                "address": this.address,
                "pdpa_accept": 1,
                "gender": "m",
                "birthdate": this.birthdate,
                "prefix": this.prefix,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "phone_number": this.phone_number,
                "weight": this.weight,
                "height": this.height,
                "highpressure": this.highpressure,
                "hyperlipidemia": this.hyperlipidemia,
                "alzheimer": this.alzheimer,
                "heart": this.heart,
                "diabetes": this.diabetes,
                "arrhythmai": this.arrhythmai,
                "depress": this.depress,
                "postcode": this.postcode
            }

            if (this.user_type == 'user_patient') {
                //console.log(this.register_form)
                //call api patient
                this.active_waiting = true
                this.title = 'กำลังลงทะเบียน',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/update_user_patient', this.register_form, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.res = response.data
                    console.log(response.data)

                    if (this.res.detail) {
                        this.title = 'แก้ไขข้อมูลสำเร็จ โปรดออกจากระบบ <br> และเข้าใช้งานใหม่อีกครั้งเพื่ออัพเดทข้อมูล';
                        this.status = 'info'
                        this.dialog = false
                    } 
                })
            }

        },
        getUserInfo() {
            this.res_info = this.$store.state.user_info
            console.log('this.res_info:', this.res_info)
        },
        logOut() {
            // clear storage
            this.$store.commit('clearStore', '')
            localStorage.clear()
            localStorage.removeItem('store')
            // Logout Line
            window.liff.logout();
            this.$router.push('/home')
        },
        getHomeTitle() {
            if (this.user_type === 'user_provider') {
                this.menu_title = 'ข้อมูล รพ./คลินิก'
            }
            if (this.user_type === 'user_osm') {
                this.menu_title = 'ข้อมูล อสม.'
            }
            if (this.user_type === 'user_patient') {
                this.menu_title = 'ข้อมูลผู้ใช้งาน/ผู้ป่วย'
            }
        },
    },
};
</script>

<style scoped>
.v-text-field--rounded>.v-input__control>.v-input__slot {
    padding: 0px;
}

.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.row-no-padding {
    padding-bottom: 0px;
    padding-top: 0px;
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
    margin-left: -2% !important;
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    margin-top: 3px !important;
}

.v-chip .v-chip__content {
    align-items: center;
    display: inline-flex;
    height: 100% !important;
    max-width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 32px;
    padding-left: 24px;
}
</style>
